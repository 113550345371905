// import images
const catalogueImage1 = "https://documents.mondistri.re/C_Legrend.webp";
const catalogueImage2 = "https://documents.mondistri.re/C_Schneider.webp";
const catalogueImage3 = "https://documents.mondistri.re/C_Disano.webp";
const catalogueImage4 = "https://documents.mondistri.re/C_Fosnova.webp";
const catalogueImage5 = "https://documents.mondistri.re/C_Résistex.webp";
const catalogueImage6 = "https://documents.mondistri.re/C_Ledvance.webp";
const catalogueImage7 = "https://documents.mondistri.re/C_Gewiss-Mavil.webp";
const catalogueImage14 = "https://documents.mondistri.re/C_Dahua.webp";
const catalogueImage15 = "https://documents.mondistri.re/C_Ajax2.webp";
// import catalogueImage12 from "../assets/img/Catalogues/-Page-001.jpg";
// import catalogueImage13 from "../assets/img/Catalogues/C_COMUNELLO.png";
// import catalogueImage18 from "../assets/img/Catalogues/C_Basor.png";
// import catalogueImage16 from "../assets/img/Catalogues/C_Spit.png";
// import catalogueImage17 from "../assets/img/Catalogues/C_VTAC.png";
// import catalogueImage20 from "../assets/img/Catalogues/V-TAC-Solar.jpg";



const Url1 = 'https://documents.mondistri.re/legrand-catalogue-france.pdf';
const Url2 = "https://www.document.schneider-electric.fr/catalog/schneider-electric-avril-2023/";
const Url3 = "https://issuu.com/disano-fosnova/docs/disano_2024_fra_unito?fr=sYzlmODY4MjY4MzA";
const Url4 = "https://issuu.com/disano-fosnova/docs/fra_fosnova_led_24-25?fr=xGAEoAT3_NTU1";
const Url5 = "https://46e049eb9fe34ead907d650c8a630773.elf.site/";
const Url6 = "https://www.ledvance.fr/00_Free_To_Use/asset-13240464_catalogue_general_eclairage_professionnel_2025.pdf";
const Url7 = "https://documents.mondistri.re/c_gewiss-mavil_2023-2024";
const Url14 = "https://documents.mondistri.re/Dahua-France-Catalogue.pdf";
const Url15 = "https://online.fliphtml5.com/jcvvc/kgaf/";
// const Url12 = "https://documents.mondistri.re/CATALOGUE_UNIFORMATIC_VDI_2023_HD.pdf";
// const Url13 = "https://documents.mondistri.re/c_comunelo_2023";
// const Url18 = "https://documents.mondistri.re/BASOR-catalogue.pdf";
// const Url16 = "https://fr.calameo.com/read/006570162c531af615c0c?page=1";
// const Url17 = "https://gheledpro.fr/catalogue/v-tac/";
// const Url20 = "http://documents.mondistri.re/V-TAC_Solar_Energy-Catalog.pdf";

const Download1 = "https://documents.mondistri.re/legrand-catalogue-france.pdf"


const catalogueData = [
  {
    id: 1,
    image: catalogueImage1,
    url: Url1,
    download: Download1,
    alt: "Catalogue Legrand",
  },
  {
    id: 2,
    image: catalogueImage2,
    url: Url2,
    alt: "Catalogue Schneider",
  },
  {
    id: 3,
    image: catalogueImage3,
    url: Url3,
    alt: "Catalogue Disano",
  },
  {
    id: 4,
    image: catalogueImage4,
    url: Url4,
    alt: "Catalogue Disano-Fosnova",
  },
  {
    id: 5,
    image: catalogueImage5,
    url: Url5,
    alt: "Catalogue Ledvance",
  },
  {
    id: 6,
    image: catalogueImage6,
    url: Url6,
    alt: "Catalogue Résistex",
  },
  {
    id: 7,
    image: catalogueImage7,
    url: Url7,
    alt: "Catalogue Gewiss-Mavil",
  },
  // {
  //   id: 8,
  //   image: catalogueImage8,
  //   url: Url8,
  //   alt: "Catalogue Ajax",
  // },
  // {
  //   id: 9,
  //   image: catalogueImage9,
  //   url: Url9,
  //   alt: "Catalogue Schneider",
  // },
  // {
  //   id: 10,
  //   image: catalogueImage10,
  //   url: Url10,
  //   alt: "Catalogue V-TAC Solar",
  // },
  // {
  //   id: 11,
  //   image: catalogueImage11,
  //   url: Url11,
  //   alt: "Catalogue Disano",
  // },
  // {
  //   id: 12,
  //   image: catalogueImage12,
  //   url: Url12,
  //   alt: "Catalogue Fosnova",
  // },
  // {
  //   id: 13,
  //   image: catalogueImage13,
  //   url: Url13,
  //   alt: "Catalogue Résistex",
  // },
  {
    id: 14,
    image: catalogueImage14,
    url: Url14,
    alt: "Catalogue Dahua",
  },
  {
    id: 15,
    image: catalogueImage15,
    url: Url15,
    alt: "Catalogue Ajax",
  },

];

export default catalogueData;
